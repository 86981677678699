import axios from 'axios';
import config from './config';

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

const withErrorHandling = async (func) => {
  try {
    const response = await func();
    return response.data;
  }
  catch (ex) {
    if (ex.response && ex.response.status === 404) {
      return null;
    }
    throw ex;
  }
}

export default {
  getById: async (id) => {
    return await withErrorHandling(() => client.get(`/inventory/${id}`));
  },
  getCarouselAds: async (url, offset, sort) => {
    return await withErrorHandling(() => client.get(`/inventory/carousel/${url}?offset=${offset}&sort=${sort}`));
  }
}