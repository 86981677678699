module.exports = {
  loadAds: ads => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      googletag.destroySlots();
      for (let ad of Object.values(ads)) {
        if (ad.static) {
          continue;
        }
        if (ad.sizes) {
          // Ref:  https://www.monterail.com/blog/gpt-ads-in-spa-next.js
          var mapping = googletag.sizeMapping()

          for (let size of ad.sizes) {
            mapping = mapping.addSize([size.width, 0], [[size.width, size.height]]);
          }

          mapping = mapping
            .addSize([0, 0], [])
            .build();

          googletag
            .defineSlot(ad.unitId,
              ad.sizes.map(size => {
                return [size.width, size.height];
              }),
              ad.elementId)
            .defineSizeMapping(mapping)
            .addService(googletag.pubads());

        }
        else {
          googletag
            .defineSlot(ad.unitId, [ad.width, ad.height], ad.elementId)
            .addService(googletag.pubads());
        }

      }
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    })
  }
}