import React from 'react'
import Creatable from "react-select/creatable";

// AJB: should come from server?
const priceValues = [
  0, 10000, 20000, 30000, 40000, 50000, 75000, 100000, 150000
]

const formatter = new Intl.NumberFormat('en-US');

function PriceRangeCombobox({ label, value, onChange, anyLabel }) {
  const currentOption = CreateOption(value);
  const priceOptions = priceValues.map(value => CreateOption(value));

  function CreateOption(value) {
    return { label: value ? '$' + formatter.format(value) : anyLabel, value: value || 0 };
  }

  const handleNumberOnlyInput = e => {
    if (e.key.length === 1 && !isFinite(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Creatable
      aria-label={label}
      options={priceOptions}
      createOptionPosition='first'
      formatCreateLabel={(value) => '$' + formatter.format(value)}
      onKeyDown={handleNumberOnlyInput}
      onChange={(value) => {
        const numberValue = Number(value.value.toString().replace(',', ''));
        onChange(numberValue);
      }}
      value={currentOption}
      styles={{
        control: base => ({
          ...base,
          height: 38,
          minHeight: 38
        }),
        container: base => ({
          ...base,
          width: '100%'
        }),
        input: base => ({
          ...base,
          fontFamily: 'Montserrat-Regular',
          '& input': {
            font: 'inherit'
          }
        }),
        option: (styles) => {
          return {
            ...styles,
            color: 'black'
          }
        }
      }}
    />
  )
}

export default PriceRangeCombobox