import React, { useEffect, useState } from 'react'
import { Button, Message, Modal, Header } from 'semantic-ui-react';
import memberSaveSearchService from '../../services/MemberSaveSearchService';
import { useAuth } from '../../hooks/use-auth'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components';
import layout from '../../services/layout';
import AnalyticEventService from '../../services/AnalyticEventService';

const SavedSearchContainer = styled.div`
  .ui.basic.inverted.button {
    box-shadow: 0 0 0 ${layout.buttons.outlineThickness} ${layout.buttons.inactiveOutline} inset !important;
    white-space: nowrap;
  }
`

const SavedLabel = styled.span`
  max-width: 150px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
`

function SaveSearch({ canonicalUrl, searchName, source }) {
  const auth = useAuth();
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpenState] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const savedSearch = auth.memberSavedSearch !== null && auth.memberSavedSearch.find((search) => search.url == canonicalUrl);

  useEffect(() => {
    if (auth.memberSavedSearch !== null || (auth.user && auth.user.initialized && !auth.user.authenticated)) {
      setIsLoading(false);
    }
  }, [auth.memberSavedSearch, auth.user]);

  const checkAuthentication = () => {
    const isAuthenticated = !!auth.user.userId;
    if (!isAuthenticated) {
      router.push('/authenticate?returnUrl=' + encodeURIComponent(router.asPath));
      CreateAnalyticEvent('AUTH_REDIRECT', 'SEARCH_SAVE');
    }
    return isAuthenticated;
  }

  const handleSaveSearchClick = async (e) => {
    try {
      if (checkAuthentication()) {
        setIsLoading(true);

        //Save member search
        let result = await memberSaveSearchService.saveMemberSearch(auth.user.userId, canonicalUrl, searchName);
        if (!result.success) {
          setErrorMessage({
            title: 'Error',
            body: result.message
          });
          setModalOpenState(true)
        }
        else {
          auth.updateMemberSavedSearch(true, canonicalUrl, searchName);
          CreateAnalyticEvent('SEARCH_SAVE', null, result.searchId);
        }
      }
    }
    catch (ex) {
      console.error('failed to save search', ex);
      setErrorMessage({
        title: 'Error',
        body: 'Sorry, we couldn\'t save this search currently.'
      });
      setModalOpenState(true)
    }
    finally {
      setIsLoading(false);
    }
  }

  const CreateAnalyticEvent = async (eventType, redirectSource, searchId) => {
    await AnalyticEventService.createAnalyticEvent({
      eventType: eventType,
      metadata: {
        userId: auth.user.userId,
        canonicalUrl: canonicalUrl,
        name: searchName,
        source: source,
        redirectSource: redirectSource,
        searchId: searchId
      }
    });
  }

  return (
    <SavedSearchContainer>
      {!savedSearch
        ?
        <Button
          basic
          inverted
          icon='bell outline'
          content={'Subscribe'}
          onClick={() => {
            if (checkAuthentication()) {
              handleSaveSearchClick();
            }
          }}
          loading={isLoading} disabled={isLoading}
          aria-label='Subscribe' />
        :
        <Link href={'/member/saved-searches/' + auth.user.userId}>
          <Button
            basic
            inverted
            icon='bell'
            content={<SavedLabel>Subscribed</SavedLabel>}
            loading={isLoading} disabled={isLoading}
            aria-label='Saved Searches' />
        </Link>
      }

      {errorMessage &&
        <Modal
          open={modalOpen}
          onClose={() => setModalOpenState(false)}
          onOpen={() => setModalOpenState(true)}
        >
          <Header icon='remove' content={errorMessage.title} />
          <Modal.Content>
            <Message negative>
              <p>{errorMessage.body}</p>
            </Message>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => setModalOpenState(false)}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      }
    </SavedSearchContainer>
  )
}

export default SaveSearch
