import React, { useEffect } from 'react'
import styled from 'styled-components'
import Image from 'next/image'

const AdContainer = styled.div`
  @media (max-width: ${props => props.$minWidth}px) {
    display: none;
  }
`

function AdModule({ config }) {
  const willRender = typeof window !== 'undefined' && (!config.minWidth || (window.innerWidth > config.minWidth));

  useEffect(() => {
    if (willRender) {
      setTimeout(() => {
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(function () { window.googletag.display(config.elementId); });
      })
    }
  }, []);

  return <div>
    <AdContainer
      $minWidth={config.minWidth}
      static={config.static}
      id={config.elementId}
      style={{ minWidth: config.width + 'px', minHeight: config.height + 'px', textAlign: config.align }}>
      {config.static &&
        <a href={config.static.url} target="_blank" rel="noopener noreferrer">
          <Image src={config.static.image} width={config.width} height={config.height} alt={config.static.alt} />
        </a>
      }
    </AdContainer >
  </div>
}

export default AdModule
