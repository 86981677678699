import React, { useState, useEffect } from 'react';
import ListingCarousel from '../Home/ListingCarousel';
import AnalyticEventService from '../../services/AnalyticEventService';

function AuctionsInProgressCarousel({ source }) {
  const [auctionsInProgress, setAuctionsInProgress] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await fetch('/api/auctions/in-progress');
      const auctionsInProgress = await response.json();
      setAuctionsInProgress(auctionsInProgress.results);

      const analyticEvent = {
        eventType: 'AD_IMPRESSIONS',
        ads: auctionsInProgress.results.map((ad, index) => ({
          id: ad.id,
          placement: 'AUCTIONS_ENDING_SOON',
          position: index + 1
        })),
        metadata: {
          source: source
        }
      };
      await AnalyticEventService.createAnalyticEvent(analyticEvent);
    })();
  }, []);

  return auctionsInProgress.length > 0
    ? <ListingCarousel
      title='Auctions In Progress'
      serverAds={auctionsInProgress}
      ctaUrl='/search/auction'
      subscribeUrl='auctions/'
      placement={'CAROUSEL_AUCTIONS_IN_PROGRESS'}
      showActions
    />
    : <div></div>
}

export default AuctionsInProgressCarousel
